import { useState } from 'react';
import axios from 'axios';
import './Style.css';

function App() {
  const [title,setTitle] = useState('');
  const [content,setContent] = useState('');
  const [email,setEmail] = useState('');
  const [submitValue, setSubmitValue] = useState("Publier l'Annonce");

  const handleSubmit = () => {
    if(title.length === 0){
      alert("Le titre ne doit pas être vide");
    }
    else if(content.length === 0){
      alert("le contenu de la publication ne doit pas être vide");
    }
    else if(email !== "blogpost@cecfrance.com"){
      alert("L'Email que vous avez indiqué est incorrect");
    }
    else{
      const url = 'https://data.cecfrance.com/api/publications.php';
      let fData = new FormData();
      fData.append('title', title);
      fData.append('content', content);
      fData.append('email', email);
      setTitle('');
      setEmail("");
      setContent('');
      setSubmitValue("Publication réussie")
      axios.post(url, fData).then().catch(error=> alert(error));
      alert("Votre annonce a belle est bien été publier!!")
    }
  }

  return (
    <>
      <div className='container'>
        <div className="container-login">
          <div className="login-title">
            <h1>23e CEC France</h1>
            <p>
              Page de publications des Annonces de la Paroisse CEC France
              à la disposition du secretariat.
            </p>
          </div>
          <div className="login-form">
            <div className="form">
                <div className="form-row">
                    <input
                        type="text"
                        name="title"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Ecrivez le titre de l'annonce ici..."
                    />
                </div>
                <div className="form-row">
                    <input
                        type="email"
                        placeholder="Mot de passe de l'admin..."
                        name="email"
                        id="email" value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-row">
                  <textarea
                      name="content" id="content"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      placeholder="Ecrivez le contenu de l'annonce ici ..."
                  >
                  </textarea>
                </div>
                <div className="form-button">
                  {/*<button className="bt-login-submit">Publier l'annonce</button>*/}
                    <input
                        className="bt-login-submit"
                        type="submit"
                        value={submitValue}
                        name='submitVal'
                        onClick={handleSubmit}
                    />
                </div>

            </div>
          </div>
          <div className="login-footer">
            <p><a href="https://www.cecfrance.com"><span>Cliquer ici</span> pour retourner à la page principale</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
